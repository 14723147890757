var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "position": "relative",
      "margin-bottom": ['1rem', '2rem']
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": ['8px', '2rem']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "padding": "0px",
      "width": "30px",
      "height": "30px",
      "min-width": "30x",
      "display": ['none', 'flex'],
      "align-items": "center"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-chevron-left.svg'),
      "height": "20px",
      "width": "20px"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "background-color": "#CDEDFC",
      "padding": ['0.2rem', '0.5rem'],
      "width": "100%",
      "gap": "2rem"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['10px', '14px']
    }
  }, [_vm._v(" Kamu dapat menyimpan panduan makan ke perangkat. ")]), _c('c-button', {
    attrs: {
      "font-size": ['10px', '14px'],
      "font-weight": "400",
      "size": "sm",
      "variant-color": "primary",
      "variant": "outline",
      "background-color": "neutral.white",
      "border-radius": "6px",
      "height": ['15px', '25px'],
      "gap": "0.2rem",
      "padding": "0 4px",
      "disabled": _vm.isDownloading,
      "min-width": "55px"
    },
    on: {
      "click": _vm.downloadMealPlan
    }
  }, [_vm._v(" Unduh "), _c('c-box', {
    attrs: {
      "height": "100%"
    }
  }, [_vm.isDownloading ? _c('c-circular-progress', {
    attrs: {
      "padding": ['1px', '4px'],
      "width": "100%",
      "height": "100%",
      "is-indeterminate": "",
      "color": "primary"
    }
  }) : _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-save-alt.svg'),
      "height": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1)], 1)], 1), _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.isShowPopupSuccessDownload ? _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "background-color": "#C7F9E3",
      "padding": ['0.2rem', '0.5rem'],
      "width": "100%",
      "gap": "2rem",
      "position": "absolute",
      "top": "120%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['10px', '14px']
    }
  }, [_vm._v(" Berhasil mengunduh panduan makan ")])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }